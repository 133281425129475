<template>
  <div class="content page-not-found">
    <div class="vertical-middle">
      <div class="heading-block center nobottomborder">
        <h1 v-if="noShopError">{{ $t('no-shop-error') }}</h1>
        <h1 v-else>{{ $t('page-not-found') }}</h1>
      </div>
      <div class="center" v-if="noShopError">
        <router-link  :class="'nos-vignerons'" :to="{ name : 'nos-vignerons', params : { lang : currentLanguageCode }}">{{ $t("menu.nos-vignerons") }}</router-link>
      </div>
    </div>
  </div>
</template>

<style>
  .page-not-found .heading-block::after {
    display:none;
  }
</style>

<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import { mapGetters } from '@fwk-node-modules/vuex';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { languagesTypes, routerTypes } from '@fwk-client/store/types';

@Component({
  computed: {
      ...mapGetters({
          currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE
        })
  }
})
export default class PageNotFound extends mixins(GenericPage) {

  noShopError:any = null;

  created() {
    var redirectData = this.$store.getters['router/' + routerTypes.getters.GET_REDIRECT_DATA];
    if(redirectData && redirectData.noShopError) {
      this.noShopError = redirectData.noShopError;
    }
  }
 
}
</script>